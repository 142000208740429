import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import DirectorProfile from 'src/components/tournament-director-profile/tournament-director-profile';
import { usePrivateRoute } from 'src/utils/auth';

const TournamentDirectorProfile = () => {
  usePrivateRoute();
  return (
    <Router basepath="/tournaments/directors">
      <DirectorProfileRoute path="/:directorId" />
    </Router>
  );
};

interface DirectorProfileProps extends RouteComponentProps {
  directorId?: string;
}

const DirectorProfileRoute: React.FC<DirectorProfileProps> = ({ directorId }) => {
  return (
    <Layout>
      <SEO title="Tournament Director Profile" />
      <DirectorProfile directorId={directorId} />
    </Layout>
  );
};

export default TournamentDirectorProfile;
