import React, { ReactNode, useCallback, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import Button, { ButtonProps } from 'src/components/button/button';

import * as styles from './modal.module.less';

interface ModalProps {
  show: boolean;
  sectionClassname?: string;
}

interface FooterProps {
  alignLeft?: boolean;
}

interface SubComponents {
  Header: React.FC;
  Title: React.FC;
  Body: React.FC;
  Footer: React.FC<FooterProps>;
}

export const Modal: React.FC<ModalProps> & SubComponents = ({ show, children, sectionClassname }) => {
  return (
    <div className={show ? styles.modal : styles.modalHidden} aria-hidden={!show}>
      <section className={cx(styles.main, sectionClassname)}>{children}</section>
    </div>
  );
};

export const Header: React.FC = ({ children }) => {
  return <div className={styles.header}>{children}</div>;
};

export const Title: React.FC = ({ children }) => {
  return <h3 className={styles.title}>{children}</h3>;
};

export const Body: React.FC = ({ children }) => {
  return <div className={styles.body}>{children}</div>;
};

export const Footer: React.FC<FooterProps> = ({ children, alignLeft }) => {
  return <div className={alignLeft ? styles.footerLeft : styles.footer}>{children}</div>;
};

Modal.Header = Header;
Modal.Title = Title;
Modal.Body = Body;
Modal.Footer = Footer;

interface ModalButtonProps extends Pick<ButtonProps, Exclude<keyof ButtonProps, 'onClick'>> {
  onClick?: (setVisible: (visible: boolean) => any) => any;
}

export interface ModalButton {
  content?: ReactNode;
  props?: ModalButtonProps;
  hidden?: boolean;
  custom?: ReactNode;
}

export interface ActionButton extends ModalButton {
  id: any;
  disableAutoClose?: boolean;
}

export interface ButtonModalProps {
  openButton: ModalButton;
  actionButtons?: ActionButton[];
  cancelButton?: ModalButton | boolean;
  additionalFooterContent?: ReactNode;
  title?: string;
  modalSize?: string;
  buttonsLeft?: boolean;
  onVisibilityChange?: (visible: boolean) => void;
  show?: boolean;
  bodyComponent?: React.ComponentType;
  hideCloseButton?: boolean;
  showBehaviour?: 'unmount' | 'hide';
}

const ButtonModal: React.FC<ButtonModalProps> = ({
  title,
  openButton,
  actionButtons,
  cancelButton,
  buttonsLeft,
  hideCloseButton,
  onVisibilityChange,
  show: controlledShow,
  bodyComponent: BodyComponent,
  additionalFooterContent,
  children,
  showBehaviour = 'hide',
}) => {
  const [internalShow, setInternalShow] = useState(false);
  const setVisibile = useCallback(
    (visible: boolean) => {
      onVisibilityChange?.(visible);
      setInternalShow(visible);
    },
    [setInternalShow, onVisibilityChange],
  );

  const addVisiblityControls = useCallback(
    (visible: boolean, props?: ModalButtonProps, disableAutoClose?: boolean) => {
      return {
        ...(props ?? {}),
        onClick: () => {
          if (props?.onClick) {
            props.onClick(setVisibile);
          } else if (!disableAutoClose) {
            setVisibile(visible);
          }
        },
      };
    },
    [setVisibile],
  );
  const { t } = useTranslation();

  const cancel = cancelButton === true ? {} : cancelButton;
  const show = controlledShow ?? internalShow;

  const body = (
    <>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer alignLeft={buttonsLeft}>
        {cancel && (
          <Button
            type={'button'}
            {...{
              size: 'md',
              level: 'tertiary',
              ...addVisiblityControls(false, cancel.props),
            }}
          >
            {cancel.content || t('cancel')}
          </Button>
        )}
        {actionButtons?.map(({ props, content, id, disableAutoClose }) => {
          return (
            <Button
              key={id}
              {...{
                size: 'md',
                spacing: { margins: { sm: 'horizontal' } },
                ...addVisiblityControls(false, props, disableAutoClose),
              }}
            >
              {content}
            </Button>
          );
        })}
        {additionalFooterContent}
      </Modal.Footer>
    </>
  );

  return (
    <>
      {!openButton?.hidden && (
        <Button type="button" {...{ ...addVisiblityControls(true, openButton?.props) }}>
          {openButton?.content}
        </Button>
      )}
      {openButton?.custom && openButton.custom}
      {(showBehaviour === 'hide' || show) && (
        <Modal show={show}>
          <Modal.Header>
            {title && <Modal.Title>{title}</Modal.Title>}
            {!hideCloseButton && (
              <button onClick={() => setVisibile(false)} className={styles.close} type="button">
                <FaTimes />
              </button>
            )}
          </Modal.Header>
          {BodyComponent ? <BodyComponent>{body}</BodyComponent> : body}
        </Modal>
      )}
    </>
  );
};

export default ButtonModal;
